/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, MenuItem, SelectChangeEvent, Stack } from "@mui/material";
import { useFormik } from "formik";
import React, { startTransition } from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";

import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import {
  LoadState,
  MODULE_IDS,
  POLICIES,
} from "../../../../../../../constants/enums";
import { InsurerCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches";
import { UsersAutoSearchByRole } from "../../../../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { ICommAutomobilePolicyBasicDetailsProps } from "../CommAutoBasicDetailsprops.types";
import {
  addCommAutomobilePolicyAdminsync,
  addCommAutomobilePremiumAsync,
  fetchCommAutomobilePolicyAdminAsync,
  fetchCommAutomobilePremiumAsync,
} from "../../../../../../../redux/commAutoPolicy/commAutoBasicDetails/commAutoBasicDetailsActions";
import { ComissionCodeSelect } from "../../../../../../../components/AutoCompleteSearches/ComissionCodeSelect";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { useTabsUncontrolledContext } from "../../../../../../../contexts/TabsContextProvider/TabsContextUncontrolledProvider";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { batch } from "react-redux";
import { ISelectOption } from "../../../../../../../constants/types";
import { api } from "../../../../../../../api/api";
import { IInsurerCode } from "../../../../../../../redux/insurer/insurer.types";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import { renderDropdownValue } from "../../../../../../../helpers";
import { ExtendableSelect } from "../../../../../../../components/formsComponents/ExtendableSelect";
import { showMessage } from "../../../../../../../redux/messages/messagesActions";
import { fetchAccountingTaxByPolicy } from "../../../../../../../redux/Accounting/Tax/TaxActions";

export const CommAutomobilePolicyAdmin: React.FC<
  ICommAutomobilePolicyBasicDetailsProps
> = (props) => {
  const { customerpolicyId } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.basicDetails.policyAdmin
  );
  const { data: premiumData, loading: premiumLoading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.basicDetails.premium
  );

  const dispatch = useDispatchWrapper();
  const { customerProspect } = useSelector(
    (storeState: IStoreState) => storeState.customer
  );
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const { changeStep } = useTabsUncontrolledContext();
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
    submitForm,
  } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      if (!values.producer_one) {
        errors.producer_one = "Producer 1 is requried";
      }
      return errors;
    },
    onSubmit: async (values) => {
      return new Promise((resolve, reject) => {
        dispatch(
          addCommAutomobilePolicyAdminsync(
            {
              ...values,
              customer_policy_id: customerpolicyId,
            },
            (isSuccess, error) => {
              if (error) {
                reject(error);
              }
              resolve(isSuccess);
            }
          )
        );
      });
    },
  });

  const formikPremium = useFormik({
    initialValues: premiumData,
    validate: (values) => {
      const errors: any = {};
      if (values.tax_1 && Number(values.tax_1) > 100) {
        errors.tax_1 = "Tax 1 % cannot be greater than 100";
      }
      if (values.tax_2 && Number(values.tax_2) > 100) {
        errors.tax_2 = "Tax 2 % cannot be greater than 100";
      }
      return errors;
    },
    onSubmit: async (values) => {
      return new Promise((resolve, reject) => {
        dispatch(
          addCommAutomobilePremiumAsync(
            {
              ...values,
              customer_policy_id: customerpolicyId,
            },
            (isSuccess, error) => {
              if (error) {
                reject(error);
              }
              resolve(isSuccess);
            }
          )
        );
      });
    },
  });

  const handleFinalSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Submit Premium form and wait for success callback
    try {
      const adminSubmitSuccess = await submitForm();
      const premiumSubmitSuccess = await formikPremium.submitForm();

      // Check if both forms' API calls succeeded
      if (adminSubmitSuccess && premiumSubmitSuccess) {
        dispatch(
          showMessage({
            type: "success",
            message: "Policy admin saved successfully!",
            displayAs: "snackbar",
          })
        );
        if (buttonClicked === "saveAndNext") {
          changeStep(2); // Move to the next step if both forms succeed
        }
      }
    } catch (error: any) {
      dispatch(
        showMessage({
          type: "error",
          message: error.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  const handleInsurer = async (value: ISelectOption) => {
    setFieldValue("insurer", value.value);
    try {
      const res = await api.get(
        `/insurance/get-insurer-code?column=insurer_code&column=name&value=${value.value}`
      );
      const data: IInsurerCode[] = res.data.data;
      if (data) {
        formikPremium.setFieldValue(
          "agency_bill_or_direct_bill",
          data[0].default_billing
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleTaxRegion = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;

    formikPremium.setFieldValue("default_tax_region", value);
    startTransition(() => {
      dispatch(
        fetchAccountingTaxByPolicy(
          POLICIES.COMMERCIAL_AUTO_POLICY,
          value,
          (data) => {
            if (data) {
              formikPremium.setFieldValue("tax_1", data.tax_one);
              formikPremium.setFieldValue("tax_2", data.tax_two);
            }
            else {
              formikPremium.setFieldValue("tax_1", 0);
              formikPremium.setFieldValue("tax_2", 0);
            }
          }
        )
      );
    });

    formikPremium.setValues({
      ...formikPremium.values,
      default_tax_region: value,
    });
  };

  React.useEffect(() => {
    batch(() => {
      dispatch(fetchCommAutomobilePolicyAdminAsync(customerpolicyId));
      dispatch(fetchCommAutomobilePremiumAsync(customerpolicyId));
    });
  }, []);

  React.useEffect(() => {
    setValues({
      ...data,
      branch_code: customerProspect.data.branch_code,
    });
  }, [data]);

  React.useEffect(() => {
    formikPremium.setValues(premiumData);
  }, [premiumData]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <form onSubmit={handleFinalSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Insurer</CustomFormLabel>
            <InsurerCodeAutoSearch
              value={values.insurer}
              onSelect={handleInsurer}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Commission Code</CustomFormLabel>
            <ComissionCodeSelect
              insurerCode={values.insurer}
              value={values.comission_code}
              onChange={(value) => {
                setFieldValue("comission_code", value);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Branch Code</CustomFormLabel>
            <CustomTextField fullWidth value={values.branch_code} disabled />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Policy Fee</CustomFormLabel>
            <CustomTextField
              name="policy_fee"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.policy_fee}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Producer 1"
              value={values.producer_one}
              onSelect={(data) => {
                setFieldValue("producer_one", data.user_fact_id);
                setFieldValue("producer_one_code", data.user_code);
              }}
              errorMessage={errors.producer_one}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Producer 2"
              value={values.producer_two}
              onSelect={(data) => {
                setFieldValue("producer_two", data.user_fact_id);
                setFieldValue("producer_two_code", data.user_code);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Producer 3"
              value={values.producer_three}
              onSelect={(data) => {
                setFieldValue("producer_three", data.user_fact_id);
                setFieldValue("producer_three_code", data.user_code);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              value={values.marketer}
              label="Marketer"
              onSelect={(data) => {
                setFieldValue("marketer", data.user_fact_id);
                setFieldValue("marketer_code", data.user_code);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              value={values.csr}
              label="CSR"
              onSelect={(data) => {
                setFieldValue("csr", data.user_fact_id);
                setFieldValue("csr_code", data.user_code);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Billing Account Number</CustomFormLabel>
            <CustomTextField
              name="billing_account_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.billing_account_number}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Broker Code</CustomFormLabel>
            {/* <BranchCodeAutoSearch
                value={values.broker_code}
                onSelect={(value) => {
                  setFieldValue("broker_code", value.value);
                }}
              /> */}
            <CustomTextField
              name="broker_code"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.broker_code}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <LabelCard heading="Premium" />
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Policy Premium</CustomFormLabel>
            <CurrencyTextField
              fullWidth
              value={formikPremium.values.policy_premium}
              onChange={(value) => {
                formikPremium.setFieldValue("policy_premium", value);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Agency or Direct Bill</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="agency_bill_or_direct_bill"
              fullWidth
              value={formikPremium.values.agency_bill_or_direct_bill}
              onChange={formikPremium.handleChange}
              displayEmpty
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["A", "D"].map((type) => {
                return <MenuItem value={type}>{type}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Payment Plan</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={formikPremium.values.payment_plan}
              name="payment_plan"
              onChange={formikPremium.handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Monthly", "Annual", "Quarterly", "Other"].map(
                (template) => {
                  return { label: template, value: template };
                }
              )}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Premium Fincance Contract Number</CustomFormLabel>
            <CustomTextField
              name="premium_finance_contract_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={formikPremium.values.premium_finance_contract_number}
              onChange={formikPremium.handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Premium Financed?</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={formikPremium.values.premium_finance}
              name="premium_finance"
              onChange={formikPremium.handleChange}
              placeholder="Select one"
              displayEmpty
              options={[
                { label: "Yes", value: "YES" },
                { label: "No", value: "NO" },
              ]}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Financed By</CustomFormLabel>
            <ExtendableSelect
              placeholder="Select one"
              value={formikPremium.values.financed_by}
              options={["IFS", "CAFO", "First Insurance Funding", "SNAP"].map(
                (template) => {
                  return { label: template, value: template };
                }
              )}
              onChange={(newValue) => {
                formikPremium.setFieldValue("financed_by", newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Default Tax Region</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={formikPremium.values.default_tax_region}
              name="default_tax_region"
              onChange={handleTaxRegion}
              placeholder="Select One"
              displayEmpty
              options={[
                "AB",
                "BC",
                "MB",
                "NB",
                "NL",
                "NS",
                "NT",
                "NU",
                "ON",
                "PE",
                "QC",
                "SK",
                "YT",
              ].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax 1 %</CustomFormLabel>
            <CustomTextField
              name="tax_1"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={formikPremium.values.tax_1}
              error={formikPremium.errors.tax_1 ? true : false}
              helperText={formikPremium.errors.tax_1}
              onChange={formikPremium.handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax 2 %</CustomFormLabel>
            <CustomTextField
              name="tax_2"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={formikPremium.values.tax_2}
              error={formikPremium.errors.tax_2 ? true : false}
              helperText={formikPremium.errors.tax_2}
              onChange={formikPremium.handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax Exempt</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={formikPremium.values.tax_exempt}
              name="tax_exempt"
              onChange={formikPremium.handleChange}
              placeholder="Select One"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax Exempt Reason</CustomFormLabel>
            <CustomTextField
              name="reason"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={formikPremium.values.reason}
              onChange={formikPremium.handleChange}
            />
          </Grid>
        </Grid>

        <Stack direction={"row"} spacing={2} sx={{ mt: 4 }}>
          <RoleBasedCustomButton
            type="button"
            moduleId={MODULE_IDS.COMMERCIAL_AUTO}
            variant="contained"
            onClick={() => changeStep(0)}
          >
            Back
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            type="submit"
            moduleId={MODULE_IDS.COMMERCIAL_AUTO}
            variant="contained"
            onClick={() => setButtonClicked("save")}
          >
            Save
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            type="submit"
            moduleId={MODULE_IDS.COMMERCIAL_AUTO}
            variant="contained"
            onClick={() => setButtonClicked("saveAndNext")}
          >
            Save & Next
          </RoleBasedCustomButton>
        </Stack>
      </form>
    </PageLoader>
  );
};
