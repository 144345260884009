import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { initialControlLogState } from "./controlLog.types";
import {
  CLEAR_CONTROL_LOG,
  CLEAR_CONTROL_LOG_STATE,
  FETCH_CONTROL_LOG_FAILED,
  FETCH_CONTROL_LOG_LIST_FAILED,
  FETCH_CONTROL_LOG_LIST_PROGRESS,
  FETCH_CONTROL_LOG_LIST_SUCCESS,
  FETCH_CONTROL_LOG_PROGRESS,
  FETCH_CONTROL_LOG_SUCCESS,
} from "./controlLogActions";
import { LoadState } from "../../constants/enums";
import { ControlLogActions } from ".";

export const controlLogReducer = (
  state: IStoreState["controlLog"] = initialControlLogState,
  action: ControlLogActions
) => {
  switch (action.type) {
    case FETCH_CONTROL_LOG_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_CONTROL_LOG_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CONTROL_LOG_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_CONTROL_LOG_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = initialControlLogState["data"];
      });
      return newState;
    }
    case FETCH_CONTROL_LOG_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_CONTROL_LOG_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = initialControlLogState["data"];
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_CONTROL_LOG_STATE: {
      return initialControlLogState;
    }

    case CLEAR_CONTROL_LOG: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = initialControlLogState["data"];
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
