import { action } from "typesafe-actions";
import { IRole, ISecurityGroup, ISecurityNestedGroup } from "./security.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { api } from "../../api/api";
import { ISelectOption } from "../../constants/types";
import { LoadState } from "../../constants/enums";

export const FETCH_SECURITY_GROUP_PROGRESS = "FETCH_SECURITY_GROUP_PROGRESS";
export const FETCH_SECURITY_GROUP_SUCCESS = "FETCH_SECURITY_GROUP_SUCCESS";
export const FETCH_SECURITY_GROUP_FAILED = "FETCH_SECURITY_GROUP_FAILED";

export const fetchSecurityGroupProgress = () =>
  action(FETCH_SECURITY_GROUP_PROGRESS);
export const fetchSecurityGroupSuccess = (
  data: ISecurityNestedGroup,
  role: string | null,
  roleId: string | null,
  status: string | null,
) => action(FETCH_SECURITY_GROUP_SUCCESS, { data, role, roleId, status });
export const fetchSecurityGroupFailed = (errorMessage?: string) =>
  action(FETCH_SECURITY_GROUP_FAILED, { errorMessage });

const createNestedSecurityGroup = (groups: ISecurityGroup[]) => {
  const initialNestedGroup: ISecurityNestedGroup = {
    modules: {},
  };
  for (const group of groups) {
    if (initialNestedGroup.modules[group.module_name]) {
      initialNestedGroup.modules[group.module_name].children.push(group);
    } else {
      initialNestedGroup.modules[group.module_name] = {
        children: [group],
      };
    }
  }
  return initialNestedGroup;
};

const parseNestedSecurityGroups = (group: ISecurityNestedGroup) => {
  let list: ISecurityGroup[] = [];
  for (const key in group.modules) {
    const item = group.modules[key];
    let childs: any = [];
    if (item.children.length > 0) {
      childs = [...item.children];
    }
    list = [...list, ...childs];
  }
  return list;
};

export const fetchSecurityGroupAsync =
  (roleId?: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSecurityGroupProgress());
      let url = "/security/get-role-module-content-access-permission";
      if (roleId) {
        url = `/security/get-role-module-content-access-permission?role_id=${roleId}`;
      }
      const res = await api.get(url);
      const data: ISecurityGroup[] = roleId
        ? res.data.data.data
        : res.data.data;
      const roleName = roleId ? res.data.data.role_name : null;
      const finalRoleId = roleId ? res.data.data.role_id : null;
      const roleStatus = roleId ? res.data.data.status : null;
      if (data.length > 0) {
        const nested = createNestedSecurityGroup(data);
        dispatch(fetchSecurityGroupSuccess(nested, roleName, finalRoleId, roleStatus));
      } else {
        dispatch(
          fetchSecurityGroupFailed("Oops! We couldn't find any records.")
        );
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertSecurityGroupAsync =
  (
    data: ISecurityNestedGroup,
    roleId: number | null,
    roleName: string,
    status: string,

    onCallback: (isSuccess: boolean, roleId?: number) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const list = parseNestedSecurityGroups(data);
      const previousStatus = getState().security.groups.status;
      let finalRoleId = 0;
      if (!roleId || isDuplicate) {
        const res = await api.post("/role/create-role", {
          role_name: roleName,
          role_json: null,
          status: status,
        });
        finalRoleId = res.data.data.role_id;
      } else {
        finalRoleId = roleId;
      }
      if (roleId && previousStatus !== status && !isDuplicate) {
        await api.post("/role/create-role", {
          role_name: roleName,
          role_json: null,
          status: status,
        });
      }
      const finalList: ISecurityGroup[] = [];
      for (let role of list) {
        finalList.push({ ...role, role_id: finalRoleId, role_name: roleName });
      }
      await api.post(
        "/security/upsert-role-module-content-access-permission",
        finalList
      );
      onCallback(true, finalRoleId);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: "Role saved successfully!",
          type: "success",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: err.response.data.message,
          type: "error",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_ROLES_LIST_PROGRESS = "FETCH_ROLES_LIST_PROGRESS";
export const FETCH_ROLES_LIST_SUCCESS = "FETCH_ROLES_LIST_SUCCESS";
export const FETCH_ROLES_LIST_FAILED = "FETCH_ROLES_LIST_FAILED";

export const fetchRolesListProgress = () => action(FETCH_ROLES_LIST_PROGRESS);
export const fetchRolesListSuccess = (list: IRole[], totalRecords: number) =>
  action(FETCH_ROLES_LIST_SUCCESS, { list, totalRecords });
export const fetchRolesListFailed = () => action(FETCH_ROLES_LIST_FAILED);

export const fetchRolesListAsync =
  (reload?: boolean): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved = getState().security.roles.loading === LoadState.Loaded;
    try {
      if (!isSaved || reload) {
        dispatch(fetchRolesListProgress());

        const res = await api.get("/role/get-role");
        const data: IRole[] = res.data.data;

        dispatch(fetchRolesListSuccess(data, res.data.totalRecords));
      }
    } catch (err: any) {
      dispatch(fetchRolesListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchRecordDropdownAsync =
  (
    apiUrl: string,
    columnKey: string,
    columnLabel: string,
    onCallback: (isSuccess: boolean, data: ISelectOption[]) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(`${apiUrl}`);
      const data: any[] = res.data.data;
      const options: ISelectOption[] = [
        { label: "Self", value: columnKey },
        { label: "All", value: "*" },
      ];
      for (let item of data) {
        options.push({ label: item[columnLabel], value: item[columnKey] });
      }
      onCallback(true, options);
    } catch (err: any) {
      onCallback(false, []);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_SECURITY_GROUP = "CLEAR_SECURITY_GROUP";
export const clearSecurityGroup = () => action(CLEAR_SECURITY_GROUP);
