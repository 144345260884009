import { LoadState } from "../../constants/enums";
import { getUniqueId } from "../../helpers";

export interface IAllApplicationState {
  list: IAllApplication[];
  totalRecords: number;
  loading: LoadState;
  application: IAllApplication;
  applicationLoading: LoadState;
  errorApplication: string | null;
  userOffBoard: IUserOffboarding;
  userOffBoardLoading: LoadState;
  userOnBoard: IUserOnboarding;
  userOnBoardLoading: LoadState;
  rquestFormLoading: LoadState;
  requestFormList: IHrRequestFormDialog[];
  error: string | null;
}

export interface IHrRequestFormDialog {
  branch_name: string | null;
  type: string | null;
  role_id: string | null;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  phone: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  details: string | null;
  status: string;
  server: string | null;
  powerbroker: string | null;
  portal: string | null;
  applied_rating_services: string | null;
  insurer_portal_ids: string | null;
  notify_ribo: string | null;
  order_business_card: string | null;
  prior_brokerage: string | null;
}
export interface IJobExperience {
  key: string | null;
  job_title: string | null;
  from: string | null;
  to: string | null;
  employer_name: string | null;
  job_description: string | null;
}
export interface IJobReference {
  key: string | null;
  name: string | null;
  company: string | null;
  position: string | null | null;
  phone: string | null;
}
export interface IAllApplication {
  application_number: string;
  first_name: string | null;
  last_name: string | null;
  date_of_birth: string;
  email: string | null;
  mailing_address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  state_or_province: string | null;
  postal_code: string | null;
  country: string | null;
  mobile: string;
  phone: string;
  linkedin_profile: string | null;
  licenses_held: string | null;
  licenses: string[] | null;
  position_applied_for: string | null;
  branch: string | null;
  specify_other_license: string | null;
  disciplinary_action: string | null;
  languages_spoken: string | null;
  languages_written: string | null;
  job_experience: IJobExperience[];
  upload_resume: null;
  upload_cover_letter: null;
  file_name_one: string | null;
  cover_letter_one: null;
  file_name_two: string | null;
  cover_letter_two: null;
  file_name_three: string | null;
  cover_letter_three: null;
  file_name_four: string | null;
  cover_letter_four: null;
  file_name_five: string | null;
  cover_letter_five: null;
  file_name_six: string | null;
  cover_letter_six: null;
  file_name_seven: string | null;
  cover_letter_seven: null;
  file_name_eight: string | null;
  cover_letter_eight: null;
  additional_questions: {
    written_contract: string | null;
    non_solicitation_contract: string | null;
    eligible_to_work_in_canada: string | null;
    insurance_broker: string | null;
    found_guilty_of_a_criminal: string | null;
  };
  reference: IJobReference[];
  comments: string | null;
  status: string;
  follow_up_date: string;
}
export interface IUserOffboarding {
  incremental_number: number | null;
  select_user: number | null;
  date: string;
  assigned_to_id: number | null;
  office_information: {
    portal_access_to_inactive: {
      required: string | null;
      send_email: string | null;
    };
    last_day_at_work: {
      required: string | null;
      send_email: string | null;
    };
    thank_you_email: {
      required: string | null;
      send_email: string | null;
    };
    ribo_notified: {
      required: string | null;
      send_email: string | null;
    };
    credential_cancelled: {
      required: string | null;
      send_email: string | null;
    };
    user_on_server: {
      required: string | null;
      send_email: string | null;
    };
    user_power_broker: {
      required: string | null;
      send_email: string | null;
    };
    user_on_aaxel_portal: {
      required: string | null;
      send_email: string | null;
    };
    orientation_document_provided: {
      required: string | null;
      send_email: string | null;
    };
    applied_rating_services: {
      required: string | null;
      send_email: string | null;
    };
    e_signature: {
      required: string | null;
      send_email: string | null;
    };
  };
  comments: string | null;
  status: string;
}

export const initialApplication: IAllApplication = {
  application_number: "",
  first_name: null,
  last_name: null,
  date_of_birth: "",
  email: null,
  mailing_address: null,
  unit_or_suite: null,
  city: null,
  state_or_province: null,
  branch: null,
  position_applied_for: null,
  postal_code: null,
  country: null,
  mobile: "",
  phone: "",
  linkedin_profile: null,
  licenses_held: null,
  licenses: [],
  specify_other_license: null,
  disciplinary_action: null,
  languages_spoken: null,
  languages_written: null,
  follow_up_date: "",
  job_experience: [
    {
      key: getUniqueId(),
      job_title: null,
      from: null,
      to: null,
      employer_name: null,
      job_description: null,
    },
  ],
  upload_resume: null,
  upload_cover_letter: null,
  file_name_one: null,
  cover_letter_one: null,
  file_name_two: null,
  cover_letter_two: null,
  file_name_three: null,
  cover_letter_three: null,
  file_name_four: null,
  cover_letter_four: null,
  file_name_five: null,
  cover_letter_five: null,
  file_name_six: null,
  cover_letter_six: null,
  file_name_seven: null,
  cover_letter_seven: null,
  file_name_eight: null,
  cover_letter_eight: null,
  additional_questions: {
    written_contract: null,
    non_solicitation_contract: null,
    eligible_to_work_in_canada: null,
    insurance_broker: null,
    found_guilty_of_a_criminal: null,
  },
  reference: [
    {
      key: getUniqueId(),
      name: null,
      company: null,
      position: null,
      phone: null,
    },
  ],
  comments: null,
  status: "Application Recieved",
};

export const initialUserOffboarding: IUserOffboarding = {
  incremental_number: null,
  select_user: null,
  date: "",
  assigned_to_id: null,
  office_information: {
    portal_access_to_inactive: {
      required: null,
      send_email: null,
    },
    last_day_at_work: {
      required: null,
      send_email: null,
    },
    thank_you_email: {
      required: null,
      send_email: null,
    },
    ribo_notified: {
      required: null,
      send_email: null,
    },
    credential_cancelled: {
      required: null,
      send_email: null,
    },
    user_on_server: {
      required: null,
      send_email: null,
    },
    user_power_broker: {
      required: null,
      send_email: null,
    },
    user_on_aaxel_portal: {
      required: null,
      send_email: null,
    },
    orientation_document_provided: {
      required: null,
      send_email: null,
    },
    applied_rating_services: {
      required: null,
      send_email: null,
    },
    e_signature: {
      required: null,
      send_email: null,
    },
  },
  comments: null,
  status: "ACTIVE",
};

export interface IUserOnboarding {
  application_number: string;
  office_information: {
    ribo_notified: {
      required: string | null;
      send_email: string | null;
    };
    insurer_credentials_ordered: {
      required: string | null;
      send_email: string | null;
    };
    user_on_server: {
      required: string | null;
      send_email: string | null;
    };
    user_on_power_broker: {
      required: string | null;
      send_email: string | null;
    };
    user_on_aaxel_portal: {
      required: string | null;
      send_email: string | null;
    };
    user_on_applied_rating_services: {
      required: string | null;
      send_email: string | null;
    };
    user_on_e_signature: {
      required: string | null;
      send_email: string | null;
    };
    orientation_organized: {
      required: string | null;
      send_email: string | null;
    };
    orientation_docmument_provided: {
      required: string | null;
      send_email: string | null;
    };
    training_organized: {
      required: string | null;
      send_email: string | null;
    };
    training_docmument_provided: {
      required: string | null;
      send_email: string | null;
    };
    aris_agreement_sent: {
      required: string | null;
      send_email: string | null;
    };
    aris_agreement_signed: {
      required: string | null;
      send_email: string | null;
    };
    payroll_setup: {
      required: string | null;
      send_email: string | null;
    };
  };
  user_defaults: {
    role_assignment: {
      required: string | null;
      send_email: string | null;
    };
    branch_assignment: {
      required: string | null;
      send_email: string | null;
    };
    phone_number_assignment: {
      required: string | null;
      send_email: string | null;
    };
    email_account_assignment: {
      required: string | null;
      send_email: string | null;
    };
    security_group_assignment: {
      required: string | null;
      send_email: string | null;
    };
  };
  status: string;
}

export const initialUserOnboard: IUserOnboarding = {
  application_number: "",
  office_information: {
    ribo_notified: {
      required: null,
      send_email: null,
    },
    insurer_credentials_ordered: {
      required: null,
      send_email: null,
    },
    user_on_server: {
      required: null,
      send_email: null,
    },
    user_on_power_broker: {
      required: null,
      send_email: null,
    },
    user_on_aaxel_portal: {
      required: null,
      send_email: null,
    },
    user_on_applied_rating_services: {
      required: null,
      send_email: null,
    },
    user_on_e_signature: {
      required: null,
      send_email: null,
    },
    orientation_organized: {
      required: null,
      send_email: null,
    },
    orientation_docmument_provided: {
      required: null,
      send_email: null,
    },
    training_organized: {
      required: null,
      send_email: null,
    },
    training_docmument_provided: {
      required: null,
      send_email: null,
    },
    aris_agreement_sent: {
      required: null,
      send_email: null,
    },
    aris_agreement_signed: {
      required: null,
      send_email: null,
    },
    payroll_setup: {
      required: null,
      send_email: null,
    },
  },
  user_defaults: {
    role_assignment: {
      required: null,
      send_email: null,
    },
    branch_assignment: {
      required: null,
      send_email: null,
    },
    phone_number_assignment: {
      required: null,
      send_email: null,
    },
    email_account_assignment: {
      required: null,
      send_email: null,
    },
    security_group_assignment: {
      required: null,
      send_email: null,
    },
  },
  status: "ACTIVE",
};

export const initialAllApplicationState: IAllApplicationState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
  application: initialApplication,
  applicationLoading: LoadState.NotLoaded,
  errorApplication: null,
  userOffBoard: initialUserOffboarding,
  userOffBoardLoading: LoadState.NotLoaded,
  userOnBoard: initialUserOnboard,
  userOnBoardLoading: LoadState.NotLoaded,
  rquestFormLoading: LoadState.NotLoaded,
  requestFormList: [],
  error: null,
};
