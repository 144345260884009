import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import {
  CLEAR_ACCOUNTING_REPORTS,
  FETCH_ACCOUNTING_REPORTS_LIST_PROGRESS,
  FETCH_ACCOUNTING_REPORTS_LIST_FAILURE,
  FETCH_ACCOUNTING_REPORTS_LIST_SUCCESS,
  FETCH_ACCOUNTING_REPORTS_PROGRESS,
  FETCH_ACCOUNTING_REPORTS_FAILURE,
  FETCH_ACCOUNTING_REPORTS_SUCCESS,
  FETCH_ACCOUNTING_ATTACHMENTS_PROGRESS,
  FETCH_ACCOUNTING_ATTACHMENTS_SUCCESS,
  FETCH_ACCOUNTING_ATTACHMENTS_FAILURE,
} from "./ReportsActions";
import { AccountingReportsActions } from ".";
import { initialAccountingReportsState } from "./Reports.types";

export const AccountingReportsReducer = (
  state: IStoreState["accounting"]["reports"] = initialAccountingReportsState,
  action: AccountingReportsActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_REPORTS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_REPORTS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_REPORTS_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case FETCH_ACCOUNTING_ATTACHMENTS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_ATTACHMENTS_SUCCESS: {
      const { attachmentList } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.attachmentList = attachmentList
      });
      return newState;
    }

    case FETCH_ACCOUNTING_ATTACHMENTS_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_REPORTS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_REPORTS_LIST_SUCCESS: {
      const { data,totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_REPORTS_LIST_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_ACCOUNTING_REPORTS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialAccountingReportsState.data;
        draftState.error = null;
      });
      return newState;
    }

    default:
      return state;
  }
};
