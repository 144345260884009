import { action } from "typesafe-actions";
import { ITax } from "./Tax.types";
import { IDataTableV2DateState } from "../../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { makeApiCall } from "../../../helpers/postRequest";

export const FETCH_ACCOUNTING_TAX_LIST_PROGRESS =
  "FETCH_ACCOUNTING_TAX_LIST_PROGRESS";
export const FETCH_ACCOUNTING_TAX_LIST_SUCCESS =
  "FETCH_ACCOUNTING_TAX_LIST_SUCCESS";
export const FETCH_ACCOUNTING_TAX_LIST_FAILURE =
  "FETCH_ACCOUNTING_TAX_LIST_FAILURE";

export const fetchAccountingTaxListProgress = () =>
  action(FETCH_ACCOUNTING_TAX_LIST_PROGRESS);

export const fetchAccountingTaxListSuccess = (data: ITax[]) =>
  action(FETCH_ACCOUNTING_TAX_LIST_SUCCESS, { data });

export const fetchAccountingTaxListFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_TAX_LIST_FAILURE, { errorMessage });

export const fetchAccountingTaxListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingTaxListProgress());
      let finalUrl = `/accounting/get-account-tax?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl += `&to_date=${date.toDate}&advanceFilter=${JSON.stringify(
          searchValue
        )}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }

      const res = await api.get(finalUrl);
      const data: ITax[] = res.data.data;
      dispatch(fetchAccountingTaxListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAccountingTaxListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_ACCOUNTING_TAX_PROGRESS = "FETCH_ACCOUNTING_TAX_PROGRESS";
export const FETCH_ACCOUNTING_TAX_SUCCESS = "FETCH_ACCOUNTING_TAX_SUCCESS";
export const FETCH_ACCOUNTING_TAX_FAILURE = "FETCH_ACCOUNTING_TAX_FAILURE";

export const fetchAccountingTaxProgress = () =>
  action(FETCH_ACCOUNTING_TAX_PROGRESS);

export const fetchAccountingTaxSuccess = (data: ITax) =>
  action(FETCH_ACCOUNTING_TAX_SUCCESS, { data });

export const fetchAccountingTaxFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_TAX_FAILURE, { errorMessage });

export const fetchAccountingTaxAsync =
  (taxCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingTaxProgress());

      const res = await api.get(
        `/accounting/get-account-tax?tax_code=${taxCode}`
      );
      const data: ITax[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingTaxSuccess(data[0]));
      }
    } catch (err: any) {
      dispatch(fetchAccountingTaxFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchAccountingTaxByPolicy =
  (
    policyType: string,
    province: string,
    onCallback: (data: ITax | null) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      const res = await api.get(
        `/accounting/get-account-tax?line_of_business=${policyType}&province=${province}`
      );
      const data: ITax[] = res.data.data;
      if (data.length > 0) {
        onCallback(data[0])
      }
      else {
        onCallback(null);
      }
    } catch (err: any) {
      onCallback(null);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const addAccountingTaxAysnc =
  (
    data: ITax,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await makeApiCall(
        {
          url: "/accounting/edit-account-tax",
          method: "POST",
        },
        {},
        {
          ...data,
        }
      );
      let message = "Tax Information saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_ACCOUNTING_TAX = "CLEAR_ACCOUNTING_TAX";
export const CLEAR_ACCOUNTING_TAX_STATE = "CLEAR_ACCOUNTING_TAX_STATE";

export const clearAccountingTax = () => action(CLEAR_ACCOUNTING_TAX);

export const clearAccountingTaxState = () => action(CLEAR_ACCOUNTING_TAX_STATE);
