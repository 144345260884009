/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { CustomerBaiscDetailsLayout } from "../../CustomerBasicDetailsLayout/CustomerBasicDetailsLayout";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { StandadCard } from "../../../../../components/Cards";
import { Box, Divider, Tab, Tabs } from "@mui/material";
import { PolicyTabCard } from "../../../../../components/PolicyTabCard/PolicyTabCard";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../hooks";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { BreadcrumbProvider } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import {
  clearAutomobileBasicPolicyState,
  fetchAutomobileCustomerInfoAsync,
} from "../../../../../redux/automobileBasicDetails/automobileBasicDetailsActions";
import { batch } from "react-redux";
import { clearNotesState } from "../../../../../redux/notes/notesActions";
import { clearAutomobileDriverState } from "../../../../../redux/automobileDriver/automobileDriverActions";
import { clearAutomobileVehicleState } from "../../../../../redux/automobileVehicle/automobileVehicleActions";
import { clearAutomobileGarageApplicationState } from "../../../../../redux/automobileGarageApplication/automobileGarageApplicationActions";
import { clearAutomobileGaragePlatesState } from "../../../../../redux/automobileGaragePlates/automobileGaragePlatesActions";
import { clearAutomobileCSVState } from "../../../../../redux/automobileCSV/automobileCSVActions";
import { clearAutomobileScheduleState } from "../../../../../redux/automobileSchedule/automobileScheduleActions";
import { clearAutomobileAuthorizationState } from "../../../../../redux/automobilePolicyAuthorization/automobilePolicyAuthorizationActions";
import { clearAutomobileApplicationState } from "../../../../../redux/automobileApplication/automobileApplicationActions";
import { clearAutonmobilePolicyMarketingState } from "../../../../../redux/automobileMarketingHistory/automobileMarketingHistoryActions";
import { clearCommAutoCertificatesState } from "../../../../../redux/commAutoPolicy/commAutoCertificates/commAutoCertificatesActions";
import { AccountCircle, AdminPanelSettings } from "@mui/icons-material";
import { a11yProps } from "../../../../../components/Tabs/utils";
import { TabPanel } from "../../../../../components/Tabs/TabPanel";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import GppGoodIcon from "@mui/icons-material/GppGood";
import AttachmentIcon from "@mui/icons-material/Attachment";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import HistoryIcon from "@mui/icons-material/History";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

export const AutomobilePolicyLayout: React.FC = () => {
  const { customerId, customerPolicyId } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
  };
  const [tab, setTab] = useState<number>(0);
  const {
    customerPoliycId: savedCustomerPolicyId,
    customerInfo: { loading, error },
  } = useSelector(
    (storeState: IStoreState) => storeState.automobile.basicDetails
  );
  const navigate = useNavigate();
  const dispatch = useDispatchWrapper();
  const location = useLocation();
  const currentPathname = location.pathname;
  const isMainTab = currentPathname.includes("basic-details");

  const handleStep = (step: string) => () => {
    const isAlreadyInSamePage = currentPathname.includes(step);
    if (!isAlreadyInSamePage) {
      navigate(
        `/automobile-policy/${customerId}/${step}/${savedCustomerPolicyId}?tab=0`
      );
    }
  };

  const renderComponent = () => {
    if (isMainTab) {
      return <Outlet />;
    } else if (!isMainTab && !customerPolicyId) {
      return <Navigate to={`/customer-overview/${customerId}`} />;
    } else if (!isMainTab && savedCustomerPolicyId) {
      return <Outlet context={[customerId, savedCustomerPolicyId]} />;
    } else {
      console.log("__FALIED____");
      return null;
    }
  };
  const handleChangetab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchAutomobileCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    return () => {
      batch(() => {
        dispatch(clearAutomobileBasicPolicyState());
        dispatch(clearAutomobileDriverState());
        dispatch(clearAutomobileVehicleState());
        dispatch(clearAutomobileGarageApplicationState());
        dispatch(clearAutomobileGaragePlatesState());
        dispatch(clearAutomobileCSVState());
        dispatch(clearAutomobileScheduleState());
        dispatch(clearAutomobileAuthorizationState());
        dispatch(clearAutomobileApplicationState());
        dispatch(clearAutonmobilePolicyMarketingState());
        dispatch(clearCommAutoCertificatesState());
        dispatch(clearNotesState());
      });
    };
  }, []);

  const tabStyle = {
    fontWeight: 800,
    color: "Black"
  };

  return (
    <>
      <BreadcrumbProvider>
        <CustomerBaiscDetailsLayout
          customerId={customerId}
          fromPolicy
          policyId={savedCustomerPolicyId as number}
        >
          <StandadCard sx={{ my: 2 }}>
            <Box display={"flex"} flexWrap={"wrap"}>
              <PolicyTabCard
                label="Basic Details"
                onClick={handleStep("basic-details")}
                isSelected={currentPathname.includes("basic-details")}
              />

              <PolicyTabCard
                label="Driver"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("driver-tab")}
                isSelected={currentPathname.includes("driver-tab")}
              />

              <PolicyTabCard
                label="Vehicle"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("vehicle-tab")}
                isSelected={currentPathname.includes("vehicle-tab")}
              />

              <PolicyTabCard
                label="Coverage"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("vehicle-coverage-schedule-tab")}
                isSelected={currentPathname.includes(
                  "vehicle-coverage-schedule-tab"
                )}
              />
              <PolicyTabCard
                label="Applications"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("automobile-application-tab")}
                isSelected={currentPathname.includes(
                  "automobile-application-tab"
                )}
              />
              <PolicyTabCard
                label="Alberta Applications"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("automobile-alberta-application-tab")}
                isSelected={currentPathname.includes(
                  "automobile-alberta-application-tab"
                )}
              />
              <PolicyTabCard
                label="Policy Forms"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("policy-forms-tab")}
                isSelected={currentPathname.includes("policy-forms-tab")}
              />

              <PolicyTabCard
                label="History"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("history-tab")}
                isSelected={currentPathname.includes("history-tab")}
              />
              <PolicyTabCard
                label="Attachment"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("attachment-tab")}
                isSelected={currentPathname.includes("attachment-tab")}
              />
              <PolicyTabCard
                label="Abeyance/Notes"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("notes-tab")}
                isSelected={currentPathname.includes("notes-tab")}
              />
              <PolicyTabCard
                label="Business Tracker"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("task-activity-tab")}
                isSelected={currentPathname.includes("task-activity-tab")}
              />
              <PolicyTabCard
                label="CSIO Applications"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("automobile-csio-application-tab")}
                isSelected={currentPathname.includes(
                  "automobile-csio-application-tab"
                )}
              />
              <PolicyTabCard
                label="eDocs"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("csio-edocs-list")}
                isSelected={currentPathname.includes(
                  "csio-edocs-list"
                )}
              />

<PolicyTabCard
                label="eDocs/EDI"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("csio-edi-list")}
                isSelected={currentPathname.includes("csio-edi-list")}
              />
              <PolicyTabCard
                label="Policy Change"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("policy-change-req")}
                isSelected={currentPathname.includes(
                  "policy-change-req"
                )}
              />
              <PolicyTabCard
                label="Invoices"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("invoices")}
                isSelected={currentPathname.includes("invoices")}
              />
            </Box>
          </StandadCard>

          {/* <Tabs
            value={tab}
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChangetab}
            sx={{ mb: 3 }}
            
          >
            <Tab
              icon={<AccountCircle />}
              iconPosition="start"
              label="Basic Details"
              {...a11yProps(0)}
              sx={{ ...tabStyle }}
            />
            <Tab
              sx={{ minHeight: "50px" , ...tabStyle }}
              icon={<TwoWheelerIcon />}
              iconPosition="start"
              label="Driver"
              {...a11yProps(1)}
            />
            <Tab
              sx={{ minHeight: "50px" , ...tabStyle }}
              icon={<DirectionsCarIcon />}
              iconPosition="start"
              label="Vehicle"
              {...a11yProps(2)}
            />
            <Tab
              sx={{ minHeight: "50px" , ...tabStyle }}
              icon={<GppGoodIcon />}
              iconPosition="start"
              label="Coverage"
              {...a11yProps(3)}
            />
            <Tab
              sx={{ minHeight: "50px" , ...tabStyle }}
              icon={<InsertDriveFileIcon />}
              iconPosition="start"
              label="Application"
              {...a11yProps(4)}
            />
            <Tab
              sx={{ minHeight: "50px" , ...tabStyle }}
              icon={<DescriptionIcon />}
              iconPosition="start"
              label="Alberta Application"
              {...a11yProps(5)}
            />
            <Tab
              sx={{ minHeight: "50px" , ...tabStyle }}
              icon={<FolderIcon />}
              iconPosition="start"
              label="Forms"
              {...a11yProps(6)}
            />
            <Tab
              sx={{ minHeight: "50px" , ...tabStyle }}
              icon={<AttachmentIcon />}
              iconPosition="start"
              label="Attachments"
              {...a11yProps(7)}
            />
            <Tab
              sx={{ minHeight: "50px" , ...tabStyle }}
              icon={<FilePresentIcon />}
              iconPosition="start"
              label="eDocs"
              {...a11yProps(8)}
            />
            <Tab
              sx={{ minHeight: "50px" , ...tabStyle }}
              icon={<FileOpenIcon />}
              iconPosition="start"
              label="CSIO Apps"
              {...a11yProps(9)}
            />
            <Tab
              sx={{ minHeight: "50px" , ...tabStyle }}
              icon={<InsertChartIcon />}
              iconPosition="start"
              label="Business Tracker"
              {...a11yProps(10)}
            />
            <Tab
              sx={{ minHeight: "50px" , ...tabStyle }}
              icon={<HistoryIcon />}
              iconPosition="start"
              label="History"
              {...a11yProps(11)}
            />
            <Tab
              sx={{ minHeight: "50px" , ...tabStyle }}
              icon={<FormatListNumberedIcon />}
              iconPosition="start"
              label="Notes"
              {...a11yProps(12)}
            />
          </Tabs>
          <Divider />
          <TabPanel value={tab} index={0}>
            
          </TabPanel>
          <TabPanel value={tab} index={1}></TabPanel> */}
          <PageLoader
            loading={loading === LoadState.InProgress}
            error={error ? { message: error } : null}
          >
            {renderComponent()}
          </PageLoader>
        </CustomerBaiscDetailsLayout>
      </BreadcrumbProvider>
    </>
  );
};
