/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";

import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { InsurerCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches";
import { IAutomobilePolicyBasicDetailsProps } from "../AutomobileBasicDetails.types";
import {
  addAutomobilePolicyAdminManagerAsync,
  fetchAutomobilePolicyAdminManagerAsync,
} from "../../../../../../../redux/automobileBasicDetails/automobileBasicDetailsActions";
import { UsersAutoSearchByRole } from "../../../../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { ComissionCodeSelect } from "../../../../../../../components/AutoCompleteSearches/ComissionCodeSelect";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { useTabsUncontrolledContext } from "../../../../../../../contexts/TabsContextProvider/TabsContextUncontrolledProvider";

export const AutomobilePolicyAdminManager: React.FC<
  IAutomobilePolicyBasicDetailsProps
> = (props) => {
  const { customerpolicyId } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.automobile.basicDetails.policyAdminManager
  );
  const dispatch = useDispatchWrapper();
  const { customerProspect } = useSelector(
    (storeState: IStoreState) => storeState.customer
  );
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const { changeStep } = useTabsUncontrolledContext();
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      if (!values.producer_one) {
        errors.producer_one = "Producer 1 is requried";
      } else if (
        Number(values.house_percentage) +
          Number(values.branch_percentage) +
          Number(values.producer_one_percentage) +
          Number(values.producer_two_percentage) +
          Number(values.producer_three_percentage) !==
        100
      ) {
        errors.producer_three_percentage = "*Total should be 100%";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        addAutomobilePolicyAdminManagerAsync(
          {
            ...values,
            customer_policy_id: customerpolicyId,
          },
          (isSuccess) => {
            if (buttonClicked === "saveAndNext" && isSuccess) {
              changeStep(4);
            }
            setSaveLoading(false);
          }
        )
      );
    },
  });

  React.useEffect(() => {
    if (customerpolicyId) {
      dispatch(fetchAutomobilePolicyAdminManagerAsync(customerpolicyId));
    }
  }, [customerpolicyId]);

  React.useEffect(() => {
    setValues({
      ...data,
      branch_code: customerProspect.data.branch_code,
      producer_one: data.producer_one || customerProspect.data.producer,
    });
  }, [data, customerProspect]);

  return (
    <>
      <PageLoader loading={loading === LoadState.InProgress}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Insurer</CustomFormLabel>
              <InsurerCodeAutoSearch
                value={values.insurer}
                onSelect={(value) => {
                  setFieldValue("insurer", value.value);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Commission Code</CustomFormLabel>
              <ComissionCodeSelect
                insurerCode={values.insurer}
                value={values.comission_code}
                onChange={(value) => {
                  setFieldValue("comission_code", value);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={1.5}>
              <CustomFormLabel>Policy Fee</CustomFormLabel>
              <CustomTextField
                name="policy_fee"
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                value={values.policy_fee}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <CustomFormLabel>Billing Account Number</CustomFormLabel>
              <CustomTextField
                name="billing_account_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.billing_account_number}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                // disabled={isUpdateLead}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={1.8}>
              <CustomFormLabel>Broker Code</CustomFormLabel>
              {/* <BranchCodeAutoSearch
                value={values.broker_code}
                onSelect={(value) => {
                  setFieldValue("broker_code", value.value);
                }}
              /> */}
              <CustomTextField
                name="broker_code"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker_code}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                // disabled={isUpdateLead}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={1.8}>
              <CustomFormLabel>Broker Sub Code</CustomFormLabel>
              {/* <BranchCodeAutoSearch
                value={values.broker_code}
                onSelect={(value) => {
                  setFieldValue("broker_code", value.value);
                }}
              /> */}
              <CustomTextField
                name="broker_sub_code"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.broker_sub_code}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                // disabled={isUpdateLead}
                onChange={handleChange}
              />
            </Grid>
            {/* <Grid item xs={12} lg={4}>
              <CustomFormLabel>House %</CustomFormLabel>
              <CustomTextField
                name="house_percentage"
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                value={values.house_percentage}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Branch %</CustomFormLabel>
              <CustomTextField
                name="branch_percentage"
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                value={values.branch_percentage}
                onChange={handleChange}
              />
            </Grid> */}

            <Grid item xs={12} md={12}>
              <Grid container spacing={1} marginTop={3}>
                <Grid item xs={12} md={12}>
                  <Divider />
                </Grid>

                <Grid item xs={4} md={4}></Grid>
                <Grid item xs={4} md={4}>
                  <Typography>Producers</Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography>%</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider />
                </Grid>

                <Grid container spacing={1} marginTop={2}>
                  <Grid item xs={4} md={4}>
                    <Typography fontWeight={600}>House</Typography>
                  </Grid>
                  <Grid item xs={12} lg={4}></Grid>

                  <Grid item xs={12} lg={4}>
                    <CustomTextField
                      name="house_percentage"
                      variant="outlined"
                      size="small"
                      type="number"
                      fullWidth
                      value={values.house_percentage}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} marginTop={2}>
                  <Grid item xs={4} md={4}>
                    <Typography fontWeight={600}>Branch</Typography>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <CustomTextField
                      fullWidth
                      value={values.branch_code}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <CustomTextField
                      name="branch_percentage"
                      variant="outlined"
                      size="small"
                      type="number"
                      fullWidth
                      value={values.branch_percentage}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} marginTop={2}>
                  <Grid item xs={4} md={4}>
                    <Typography fontWeight={600}>Producer 1</Typography>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <UsersAutoSearchByRole
                      // label="Producer 1"
                      value={values.producer_one}
                      errorMessage={errors.producer_one}
                      onSelect={(data) => {
                        setFieldValue("producer_one", data.user_fact_id);
                        setFieldValue("producer_one_code", data.user_code);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    {/* <CustomFormLabel>%</CustomFormLabel> */}
                    <CustomTextField
                      name="producer_one_percentage"
                      variant="outlined"
                      size="small"
                      type="number"
                      fullWidth
                      value={values.producer_one_percentage}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} marginTop={2}>
                  <Grid item xs={4} md={4}>
                    <Typography fontWeight={600}>Producer 2</Typography>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <UsersAutoSearchByRole
                      // label="Producer 1"
                      value={values.producer_two}
                      errorMessage={errors.producer_two}
                      onSelect={(data) => {
                        setFieldValue("producer_two", data.user_fact_id);
                        setFieldValue("producer_two_code", data.user_code);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    {/* <CustomFormLabel>%</CustomFormLabel> */}
                    <CustomTextField
                      name="producer_two_percentage"
                      variant="outlined"
                      size="small"
                      type="number"
                      fullWidth
                      value={values.producer_two_percentage}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} marginTop={2}>
                  <Grid item xs={4} md={4}>
                    <Typography fontWeight={600}>Producer 3</Typography>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <UsersAutoSearchByRole
                      // label="Producer 3"
                      value={values.producer_three}
                      onSelect={(data) => {
                        setFieldValue("producer_three", data.user_fact_id);
                        setFieldValue("producer_three_code", data.user_code);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <CustomTextField
                      name="producer_three_percentage"
                      variant="outlined"
                      size="small"
                      type="number"
                      fullWidth
                      value={values.producer_three_percentage}
                      error={errors.producer_three_percentage ? true : false}
                      helperText={errors.producer_three_percentage}
                      // disabled={isUpdateLead}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} marginTop={2}>
                  <Grid item xs={4} md={4}>
                    <Typography fontWeight={600}>Marketer</Typography>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <UsersAutoSearchByRole
                      value={values.marketer}
                      onSelect={(data) => {
                        setFieldValue("marketer", data.user_fact_id);
                        setFieldValue("marketer_code", data.user_code);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}></Grid>
                </Grid>
                <Grid container spacing={1} marginTop={2}>
                  <Grid item xs={4} md={4}>
                    <Typography fontWeight={600}>CSR</Typography>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <UsersAutoSearchByRole
                      value={values.csr}
                      onSelect={(data) => {
                        setFieldValue("csr", data.user_fact_id);
                        setFieldValue("csr_code", data.user_code);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}></Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item lg={4}></Grid> */}
            {/* <Grid item xs={12} lg={4}>
              <UsersAutoSearchByRole
                label="Producer 1"
                value={values.producer_one}
                errorMessage={errors.producer_one}
                onSelect={(data) => {
                  setFieldValue("producer_one", data.user_fact_id);
                  setFieldValue("producer_one_code", data.user_code);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>%</CustomFormLabel>
              <CustomTextField
                name="producer_one_percentage"
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                value={values.producer_one_percentage}
                onChange={handleChange}
              />
            </Grid> */}
            {/* <Grid item lg={4}></Grid>
            <Grid item xs={12} lg={4}>
              <UsersAutoSearchByRole
                label="Producer 2"
                value={values.producer_two}
                onSelect={(data) => {
                  setFieldValue("producer_two", data.user_fact_id);
                  setFieldValue("producer_two_code", data.user_code);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>%</CustomFormLabel>
              <CustomTextField
                name="producer_two_percentage"
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                value={values.producer_two_percentage}
                // error={errors.name ? true : false}
                // helperText={errors.name}
                // disabled={isUpdateLead}
                onChange={handleChange}
              />
            </Grid> */}
            {/* <Grid item lg={4}></Grid>
            <Grid item xs={12} lg={4}>
              <UsersAutoSearchByRole
                label="Producer 3"
                value={values.producer_three}
                onSelect={(data) => {
                  setFieldValue("producer_three", data.user_fact_id);
                  setFieldValue("producer_three_code", data.user_code);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>%</CustomFormLabel>
              <CustomTextField
                name="producer_three_percentage"
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                value={values.producer_three_percentage}
                error={errors.producer_three_percentage ? true : false}
                helperText={errors.producer_three_percentage}
                // disabled={isUpdateLead}
                onChange={handleChange}
              />
            </Grid> */}
            {/* <Grid item lg={4}></Grid> */}
          </Grid>

          <Stack direction={"row"} spacing={2} sx={{ mt: 4 }}>
            <RoleBasedCustomButton
              type="button"
              moduleId={MODULE_IDS.AUTOMOBILE}
              disabled={saveLoading}
              variant="contained"
              onClick={() => changeStep(2)}
            >
              Back
            </RoleBasedCustomButton>
            <RoleBasedCustomButton
              type="submit"
              moduleId={MODULE_IDS.AUTOMOBILE}
              disabled={saveLoading}
              variant="contained"
              onClick={() => setButtonClicked("save")}
            >
              Save
            </RoleBasedCustomButton>
            <RoleBasedCustomButton
              type="submit"
              moduleId={MODULE_IDS.AUTOMOBILE}
              disabled={saveLoading}
              variant="contained"
              onClick={() => setButtonClicked("saveAndNext")}
            >
              Save & Next
            </RoleBasedCustomButton>
          </Stack>
        </form>
      </PageLoader>
    </>
  );
};
