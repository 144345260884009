import { LoadState } from "../../constants/enums";
import { IBranchOfficeState } from "./branchOffice.types";

export const initialBranchOfficeState: IBranchOfficeState = {
  branchOffice: {
    data: {
      branch_office_primary_id: null,
      branch_office_id: null,
      name: "",
      branch_code: "",
      non_inheritable: null,
      logo: null,
      description: null,
      address: "",
      city: "",
      province_or_state: "",
      country: "",
      postal_code: "",
      branch_manager: "",
      department: null,
      email: null,
      telephone: null,
      ext: null,
      toll_free: null,
      fax: null,
      bonus_liability_code: null,
      bonus_expense_code: null,
      commission_liability_code: null,
      commission_expense_code: null,
      website: null,
      unit_or_suite: "",
      cl_email: null,
      accounts_email: null,
      status: "ACTIVE",
      insert_ts: "",
    },
    loading: LoadState.NotLoaded,
    saveLoading: false,
    error: null,
  },
};
