import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {  initialAcitvityState } from "./activity.types";
import { activityActions } from ".";
import { CLEAR_BUSINESS_TASKS_STATE, FETCG_CUSTOMER_INVOICE_LIST_FAILED, FETCG_CUSTOMER_INVOICE_LIST_PROGRESS, FETCG_CUSTOMER_INVOICE_LIST_SUCCESS, FETCH_BUSINESS_TASKS_FAILED, FETCH_BUSINESS_TASKS_LIST_FAILED, FETCH_BUSINESS_TASKS_LIST_PROGRESS, FETCH_BUSINESS_TASKS_LIST_SUCCESS, FETCH_BUSINESS_TASKS_PROGRESS, FETCH_BUSINESS_TASKS_SUCCESS, FETCH_EMAIL_CONVERSATION_LIST_FAILED, FETCH_EMAIL_CONVERSATION_LIST_PROGRESS, FETCH_EMAIL_CONVERSATION_LIST_SUCCESS, FETCH_SMS_CONVERSATION_LIST_FAILED, FETCH_SMS_CONVERSATION_LIST_PROGRESS, FETCH_SMS_CONVERSATION_LIST_SUCCESS, FETCH_WHATSAPP_CONVERSATION_LIST_FAILED, FETCH_WHATSAPP_CONVERSATION_LIST_PROGRESS, FETCH_WHATSAPP_CONVERSATION_LIST_SUCCESS } from "./activityActions";


export const activityReducer = (
  state: IStoreState["acitivty"] = initialAcitvityState,
  action:  activityActions
) => {
  switch (action.type) {
    case FETCH_BUSINESS_TASKS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.tasks.loading = LoadState.InProgress;
        draftState.tasks.list = [];
        draftState.tasks.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_BUSINESS_TASKS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.tasks.loading = LoadState.Loaded;
        draftState.tasks.list = data;
        draftState.tasks.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_BUSINESS_TASKS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.tasks.loading = LoadState.InProgress;
        draftState.tasks.list = [];
        draftState.tasks.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_BUSINESS_TASKS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.tasks.dataLoading = LoadState.InProgress;
        draftState.tasks.data = [];
      });
      return newState;
    }
    case FETCH_BUSINESS_TASKS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.tasks.dataLoading = LoadState.Loaded;
        draftState.tasks.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_TASKS_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.tasks.dataLoading = LoadState.Failed;
        draftState.tasks.error = errorMessage;
      });
      return newState;
    }

    case FETCH_EMAIL_CONVERSATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.emailConversation.loading = LoadState.InProgress;
        draftState.emailConversation.list = [];
      });
      return newState;
    }
    case FETCH_EMAIL_CONVERSATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.emailConversation.loading = LoadState.Loaded;
        draftState.emailConversation.list = data;
      });
      return newState;
    }
    case FETCH_EMAIL_CONVERSATION_LIST_FAILED: {
      const { errorMesssage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.emailConversation.loading = LoadState.Failed;
        draftState.emailConversation.error = errorMesssage;
      });
      return newState;
    }

    case FETCH_SMS_CONVERSATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.sms.loading = LoadState.InProgress;
        draftState.sms.list = [];
      });
      return newState;
    }
    case FETCH_SMS_CONVERSATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.sms.loading = LoadState.Loaded;
        draftState.sms.list = data;
      });
      return newState;
    }
    case FETCH_SMS_CONVERSATION_LIST_FAILED: {
      const { errorMesssage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.sms.loading = LoadState.Failed;
        draftState.sms.error = errorMesssage;
      });
      return newState;
    }
  

    case FETCH_WHATSAPP_CONVERSATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.whatsApp.loading = LoadState.InProgress;
        draftState.whatsApp.list = [];
      });
      return newState;
    }
    case FETCH_WHATSAPP_CONVERSATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.whatsApp.loading = LoadState.Loaded;
        draftState.whatsApp.list = data;
      });
      return newState;
    }
    case FETCH_WHATSAPP_CONVERSATION_LIST_FAILED: {
      const { errorMesssage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.whatsApp.loading = LoadState.Failed;
        draftState.whatsApp.error = errorMesssage;
      });
      return newState;
    }


    case FETCG_CUSTOMER_INVOICE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.invoice.loading = LoadState.InProgress;
        draftState.invoice.list = [];
      });
      return newState;
    }
    case FETCG_CUSTOMER_INVOICE_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.invoice.loading = LoadState.Loaded;
        draftState.invoice.list = data;
      });
      return newState;
    }
    case FETCG_CUSTOMER_INVOICE_LIST_FAILED: {
      const { error } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.invoice.loading = LoadState.Failed;
        draftState.invoice.error = error;
      });
      return newState;
    }
  
  
    case CLEAR_BUSINESS_TASKS_STATE: {
      return initialAcitvityState;
    }
    default: {
      return state;
    }
  }
};
