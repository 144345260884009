import moment from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";
import { IFileUpload } from "../../../components/FileUpload/FileUpload.type";

export interface IAccountingReportsState {
  list: IReports[];
  attachmentList: IReportAttachment[];
  listLoading: LoadState;
  data: IReports;
  loading: LoadState;
  error: string | null;
  totalRecords: number;
}

export interface IReports {
  type: string;
  accn_transaction_report_history_code: string | null;
  sub_type: string;
  general_ledger_report: string;
  order_by: string;
  view: string;
  from_date: string | null;
  to_date: string | null;
  name: string[];
  branch_code: string[];
  insurer_code: string[];
  producer_one_code: string[];
  csr_code: string[];
  line_of_business: string[];
  payment_method: string[];
  policy_transaction_type: string[];
  policy_number: string[];
  effective_date: string[];
  policy_status: string[];
  transaction_type: string[];
  policy_type: string[];
  agency_or_direct_bill: string[];
  vendor: string[];
  vendor_type: string[];
  payment_terms: string[];
  category: string[];
  gl_account: string[];
  fields_to_exports: [];
  attachment: IFileUpload[] | null;
  status: string;
}

export interface IReportAttachment {
  report_code: string;
  producer_code: string;
  producer_email: string;
  time: string | null;
  report_type: string;
  attachment: string;
  status: string;
  created_by_id: number;
  modified_by_id: number;
}

export const initialAccountingReportsState: IAccountingReportsState = {
  list: [],
  attachmentList: [],
  listLoading: LoadState.NotLoaded,
  data: {
    type: "",
    accn_transaction_report_history_code: null,
    sub_type: "",
    general_ledger_report: "",
    order_by: "invoice_date",
    view: "",
    from_date: null,
    to_date: null,
    name: ["All"],
    branch_code: ["All"],
    insurer_code: ["All"],
    producer_one_code: ["All"],
    csr_code: ["All"],
    line_of_business: ["All"],
    payment_method: ["All"],
    policy_transaction_type: ["All"],
    policy_number: ["All"],
    effective_date: ["All"],
    policy_status: ["All"],
    transaction_type: ["All"],
    policy_type: ["All"],
    agency_or_direct_bill: ["All"],
    vendor: ["All"],
    vendor_type: ["All"],
    payment_terms: ["All"],
    category: ["All"],
    gl_account: ["All"],
    fields_to_exports: [],
    attachment: null,
    status: "ACTIVE",
  },
  loading: LoadState.NotLoaded,
  totalRecords: 0,
  error: null,
};
